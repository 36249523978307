<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách tin tức</h3>
                <v-spacer />
                <v-btn color="green" dark @click="doCreate">Thêm</v-btn>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col
                    cols=4
                  >
                    <Dialog ref="ThuVien" text="Thư viện" title="Thư viện hình ảnh" :callback="LayThuVien" :closed.sync="boxThuVien">
                      <v-card flat>
                        <v-card-title>
                          <p class="text-body-1">
                            Tải lên hình ảnh
                          </p>
                          <v-file-input
                            ref="inputFile"
                            show-size
                            label="Tải hình ảnh"
                            accept="image/*"
                            @change="selectFile"
                          />
                        </v-card-title>
                        <v-card-text>
                          <v-responsive max-height="600px">
                            <v-row>
                              <v-col
                                cols="3"
                                v-for="(item,index) in images"
                                :key="index"
                              >
                                <v-card flat>
                                  <v-img
                                    :lazy-src="Link(item)"
                                    max-height="200"
                                    max-width="200"
                                    aspect-ratio="1"
                                    :src="Link(item)"
                                  >
                                  <v-card-title primary-title style="height: 100px; position: relative" v-if="boxAdd">
                                    <v-spacer />
                                    <v-btn 
                                      color="green"
                                      dark 
                                      top 
                                      right 
                                      fab
                                      @click="selectMe(index)"
                                      x-small
                                    >
                                      <v-icon>
                                        mdi-check
                                      </v-icon>
                                    </v-btn>
                                  </v-card-title>
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="grey lighten-5"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-responsive>
                        </v-card-text>
                      </v-card>
                      <Loading :status="loadingTV" />
                    </Dialog>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="DanhSachMoi"
                :search="search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
              >
                <template v-slot:[`item.Service`]="{ item }">
                  <v-btn
                    dark
                    outlined
                    :color="item.Service === 'TE' ? 'blue':item.Service === 'TO' ? 'green':'orange'"
                  >
                    {{item.Service.replace('ALL','Tất cả').replace('TE','Tom Express').replace('TO','TOJAPAN')}}
                  </v-btn>
                </template>
                <template v-slot:[`item._id`]="{ item }">
                    <Manage :item="item" :controls="CustomControls" />
                </template>
                <template v-slot:[`item.Image`]="{ item }">
                  <v-img
                    max-height="200"
                    max-width="450"
                    aspect-ratio="1"
                    :lazy-src="Link(item.Image)"
                    :src="Link(item.Image)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
                <template v-slot:[`item.Content`]="{ item }">
                  <Dialog text="Xem nội dung" title="Nội dung tin tức">
                    <v-card-title></v-card-title>
                    <v-card-text>
                      <v-responsive>
                        <div v-html="item.Content" />
                      </v-responsive>
                    </v-card-text>
                  </Dialog>
                </template>
              </v-data-table>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                />
              </v-overlay>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <Add ref="ThemTinTuc" :enable.sync="boxAdd" :addSubmit="Them" :editSubmit="Sua" :MoThuVien="MoThuVien" :loading="loadingAdd" />
  </div>
</template>

<script>
import Query from '@/plugins/query/global'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import validations from '@/plugins/helper/validations'
import {mapGetters } from 'vuex'
import Add from '@/components/news/add.vue'
import Manage from '@/components/base/thaotac.vue'
import Dialog from '@/components/base/Dialog.vue'
import Loading from '@/components/base/Loading.vue'
const MAX_WIDTH = 200
const MAX_HEIGHT = 200
export default {
  name: 'news',
  components: {
    Add,
    Manage,
    Dialog,
    Loading,
  },
  data () {
    return {
      notice: new Status(this.$swal),
      boxThuVien: false,
      valid: false,
      loading: false,
      loadingTV: false,
      loadingAdd: false,
      search: '',
      req: new Request('news'),
      headers: [
            { text: '#', value: 'index', align: 'center',width: "5%",class:"grey lighten-2 black--text" },
            { text: 'Tiêu đề', value: 'Title', align: 'center',width: "15%",class:"grey lighten-2 black--text" },
            { text: 'Nội dung', value: 'Content', align: 'center',width: "20%",class:"grey lighten-2 black--text" },
            { text: 'Thuộc DV', value: 'Service', align: 'center',width: "15%",class:"grey lighten-2 black--text" },
            { text: 'Hình ảnh', value: 'Image', align: 'center',width: "20%",class:"grey lighten-2 black--text" },
            { text: 'Thao tác', value: '_id', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
      ],
      items: [],
      images: [],
      boxAdd: false,
      imageError:false,
      progress: 0,
      currentFile: undefined
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
    DanhSachMoi: function(){
        return this.items.map(
          (items, index) => ({
            ...items,
            index: index + 1
          }))
      },
    CustomControls(){
      let Controls = [{color:'red',text:'Xóa',do:this.Xoa},{color:'green',text:'Sửa',do:this.Edit}]
      return Controls
    }
  },
  methods: {
    ...Query,
    ...validations,
    doCreate(){
      this.$refs.ThemTinTuc.doReset();
      this.boxAdd = true;
    },
    MoThuVien(){
      this.boxThuVien = false
      this.$refs.ThuVien.Open()
      console.log(this.boxThuVien)
    },
    selectMe(index){
      this.$refs.ThemTinTuc.Image(this.images[index])
      this.boxThuVien = true
    },
    Link(image){
      return `https://statics.tomexpress.jp/tomexpress/${image}`
    },
    TaiHinhAnh(){
      this.loadingTV = true
      this.req.ThuVien(this.currentFile,(event) => {
              this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(axios=>{
        const {success,error,data} = axios.data
        if(success){
          this.notice.success("Đã tải hình ảnh thành công !!!")
          this.currentFile = undefined
          this.progress = 0
          this.images = data
          this.$refs.inputFile.reset()
        }else{
          const e = error || "Không thể tải lên hình ảnh !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingTV = false
      })
    },
    Them(item){
      this.loadingAdd = true
      this.req.create(item).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã đăng tin thành công !!!")
          this.LayDuLieu()
        }else{
          const e = error||"Không thể đăng tin mới !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingAdd = false
      })
    },
    Sua(item){
      this.loadingAdd = true
      this.req.update(item._id,item).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã cập nhật tin thành công !!!")
          this.LayDuLieu()
        }else{
          const e = error||"Không thể cập nhật tin mới !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingAdd = false
      })
    },
    Xoa(item){
      this.loading = true
      this.req.delete(item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã xóa tin thành công !!!")
          this.LayDuLieu()
        }else{
          const e = error||"Không thể xóa tin !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    Edit(item){
      this.loading = true
      this.req.fetch(item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error,data} = json
        if(success){
          this.$refs.ThemTinTuc.Edit(data)
          this.boxAdd = true
        }else{
          const e = error||"Không thể tải tin !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    selectFile(file) {
      this.currentFile = undefined
      this.imageError = false
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let img = new Image();
        img.onload = () => {
          if(img.width > MAX_WIDTH) {
            this.imageError = `Chiều dài của hình (${img.width}) vượt quá mức cho phép (tối đa là ${MAX_WIDTH}).`;
            return
          }
          if(img.height > MAX_HEIGHT) {
            this.imageError = `Chiều cao của hình (${img.height}) vượt quá mức cho phép (tối đa là ${MAX_HEIGHT}).`;
            return
          }
        }
      }
      reader.onerror = evt => {
        console.error(evt)
        return
      }
      if(this.imageError !== false) return
      this.progress = 0
      this.currentFile = file
      this.TaiHinhAnh()
    },
    LayThuVien(){
      this.loadingTV = true
      this.req.fetch('thu-vien').then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,data,error} = json
        if(success){
          this.images = data
        }else{
          const e = error || "Không thể tải thư viện hình ảnh"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingTV = false
      })
    },
    LayDuLieu(){
      this.loading = true
      this.News().then(resp=>{
        this.items = resp.data
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    }
  },
  mounted(){
    if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager'){
      this.$router.push('/')
    }
    this.LayDuLieu()
  }
}
</script>
