<template>
  <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-form v-model="valid">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="doClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Thêm tin tức mới</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="addSubmit(submitData)"
              :disabled="!valid"
              v-if="editMode === false"
            >
              Thêm
            </v-btn>
            <v-btn
              dark
              text
              @click="editSubmit(submitData)"
              :disabled="!valid"
              v-else
            >
              Sửa
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          two-line
          subheader
        >
          <v-subheader>Thông tin</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col
                  cols="3"
                >
                  <v-text-field 
                    v-model="submitData.Title"
                    label="Tiêu đề"
                    :rules="[required('Tiêu đề')]"
                  />
                </v-col>
                <v-col
                    cols="3"
                >
                  <v-select
                      v-model="submitData.Service"
                      :items="[{value:'ALL',text:'Tất cả'},{value:'TE',text:'TOMEXPRESS'},{value:'TO',text:'TOJAPAN'}]"
                      label="Dịch vụ"
                      :rules="[required('Dịch vụ')]"
                  />
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-img
                    max-height="200"
                    max-width="450"
                    aspect-ratio="1"
                    :src="Link(submitData.Image)"
                  >
                    <v-card-title primary-title style="height: 100px; position: relative">
                      <v-btn 
                        color="green"
                        dark 
                        top 
                        right 
                        @click="MoThuVien"
                        x-small
                      >
                        Chọn hình ảnh
                      </v-btn>
                    </v-card-title>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          two-line
          subheader
        >
          <v-subheader>Nội Dung</v-subheader>
          <v-list-item>
            <v-list-item-content
              style="height: 250px"
            >
              <quill-editor
                v-model="submitData.Content"
                label="Nội dung"
                style="height: 200px"
                :rules="[required('Nội dung')]"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-form>
      </v-card>
      <Loading :status="loading" />
    </v-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import validations from '@/plugins/helper/validations'
import Loading from '@/components/base/Loading.vue'
export default {
  components:{
    quillEditor,
    Loading
  },
  data () {
    return {
      editMode: false,
      valid:false,
      dialog: false,
      submitData:{
        _id:undefined,
        Title: '',
        Content: '',
        Image: '',
        Service: 'ALL',
      },
    }
  },
  computed:{

  },
  watch: {
    enable: function(val){
      this.dialog = val
    },
    dialog: function(val){
      this.$emit("update:enable", val);
    }
  },
  methods: {
    ...validations,
    doClose(){
      this.dialog = false;
      this.doReset();
    },
    doReset(){
      this.submitData = {
        _id:undefined,
            Title: '',
            Content: '',
            Image: '',
            Service: 'ALL',
      };
    },
    Link(image){
      if(!image) return '/no-image.png'
      return `https://cdn.tomexpress.jp/${image}`
    },
    Image(image){
      this.submitData.Image = image
    },
    Edit(data){
      this.submitData = data
      this.editMode = true
    }
  },
  props: ["enable","addSubmit","editSubmit","MoThuVien","loading"]
}
</script>

<style>

</style>